
import {defineComponent, ref, watch} from "vue";
import {ISearchField} from "@/core/entity/ISearchField";
import {OPERATOR, SEARCH_ENTITY} from "@/core/config/Constant";
import {ISearchFilter} from "@/core/entity/ISearchFilter";
import InvoiceService from "@/core/services/InvoiceService";
import ClaimService from "@/core/services/ClaimService";
import BaseForm from "@/components/base/form/BaseForm.vue";
import BaseSelect from "@/components/base/select/BaseSelect.vue";

export default defineComponent({
  name: "FormMoveInvoice",
  components: {BaseSelect, BaseForm},
  props: {
    invoice: {type: Object}
  },
  emits: ['onMove'],
  setup(props, {emit}) {
    const refInvoice = ref(props.invoice);
    const moveCaseModel = ref<any>({claimId: ''});
    const policyData = ref<any>({data: [], total: 0, pages: 0})
    const policyField: ISearchField[] = [
      {column: 'clientId', type: 'HIDDEN', operator: OPERATOR.EQ, value: refInvoice.value?.client?.id, name: ''},
      {column: 'claimNumber', type: 'TEXT', operator: OPERATOR.LIKE, value: '', name: 'Claim#'},
    ]
    const terms = ref({number: '', certificate: ''});
    const searching = ref(false);
    const submitting = ref(false);
    const filter = ref<ISearchFilter>({
      paginationPage: 1,
      paginationSize: 20,
      query: policyField,
      entity: SEARCH_ENTITY.CLAIM_VIEW,
      fields: [],
      expands: [],
    })
    watch(() => props.invoice, cb => {
      refInvoice.value = cb;
    })
    const search = () => {
      searching.value = true
      ClaimService.search(filter.value).then(res => {
        policyData.value = res;
      }).finally(() => {
        searching.value = false;
      })
    }

    const move = () => {
      submitting.value = true;

      InvoiceService.move(refInvoice?.value?.id, moveCaseModel.value).then(res => {
        emit('onMove', res);
      }).finally(() => {
        submitting.value = false
      })
    }
    return {
      policyData,
      terms,
      filter,
      searching,
      moveCaseModel,
      search,
      submitting,
      move,
      refInvoice,
    }
  }
})
