
import { defineComponent, ref } from "vue";
import InvoiceService from "@/core/services/InvoiceService";
import { LoadPanel } from "@/core/composite/composite";

export default defineComponent({
  name: "InvoiceDuplicate",
  props: {
    invoice: {type: Object, required:true}
  },
  setup(props) {
    const data = ref([]);
    const load = (invoice: any) => {
      InvoiceService.duplicate(invoice.id, invoice.billed, invoice.type.code, invoice.dos).then(res=>{
        data.value = res
      })
    }
    load(props.invoice)
    return {
      data,
      ...LoadPanel()
    }
  }
})
